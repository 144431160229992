<template>
<!-- 刷题面板 -->
  <div class="aside-panel">
    <div class="aside-head clearfix">
      <div class="aside-lt">
        <div class="aside-title">小组刷题</div>
      </div>
      <div class="aside-rt">
        <div class="bread-tabs-head">
          <div
            class="bread-tab"
            @click="getGroupTestPrep('day')"
            :class="{ active: groupTestPrepType == 'day' }"
          >
            日榜
          </div>
          <div class="bread-tab-line">/</div>
          <div
            class="bread-tab"
            @click="getGroupTestPrep('week')"
            :class="{ active: groupTestPrepType == 'week' }"
          >
            周榜
          </div>
          <div class="bread-tab-line">/</div>
          <div
            class="bread-tab"
            @click="getGroupTestPrep('month')"
            :class="{ active: groupTestPrepType == 'month' }"
          >
            月榜
          </div>
        </div>
      </div>
    </div>
    <div class="aside-body">
      <el-table
        :data="groupTestPrep"
        size="mini"
        style="width: 100%"
        class="table"
      >
        <el-table-column type="index" label="排名" align="center" width="60">
        </el-table-column>
        <el-table-column prop="name" label="小组名" align="center" width="90">
        </el-table-column>
        <el-table-column prop="questionNum" label="刷题量" align="center">
          <template slot-scope="scope">
            {{ scope.row.questionNum }}题
            <span v-if="scope.row.comparison">
              <i class="icon iconfont icon-icon-increase"></i>
            </span>
            <span v-else>
              <i class="icon iconfont icon-icon-decrease"></i>
            </span>
          </template>
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>
<script>
import { groupTestPrep } from "../../../service/punchClock.js";
export default {
  name: "groupTestPanel",
  data() {
    return {
      groupTestPrep: [],
      groupTestPrepType: "day",
    };
  },
  mounted() {
    this.getGroupTestPrep();
  },
  methods: {
    getGroupTestPrep(type) {
      this.groupTestPrepType = type ? type : "day";
      groupTestPrep(this.groupTestPrepType,5).then((res) => {
        if (res.success) {
          this.groupTestPrep = res.result;
        }
      });
    },
  },
};
</script>
<style scoped>
.aside-rt {
  float: right;
}
.aside-lt {
  float: left;
}

.aside-panel {
  background-color: #fff;
  padding: 0 20px;
  margin-bottom: 10px;
  border-radius: 4px;
}

.aside-head {
  padding: 24px 0 6px 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.panel {
  background-color: #fff;
  margin-top: 10px;
}
.bread-tab-line {
  display: inline-block;
}
.bread-tab {
  display: inline-block;
  padding: 0 2px;
  cursor: pointer;
}

.bread-tab.active {
  color: #fa6400;
}

.aside-title {
  color: #fa6400;
  line-height: 22px;
  font-size: 14px;
}

.el-table--mini /deep/ td,
.el-table--mini /deep/ th {
  padding: 0;
  border: 0;
  color: rgba(0, 0, 0, 0.5);
}

.el-table--mini /deep/ th {
  background: rgba(0, 117, 246, 0.1);
  color: #34495e;
}

.el-table--mini /deep/ .el-table__row td:first-child .cell div {
  width: 16px;
  height: 16px;
  background: #b6daff;
  line-height: 16px;
  text-align: center;
  border-radius: 2px;
  color: #fff;
  margin: 0 auto;
}

.el-table--mini /deep/ .el-table__row:first-child td:first-child .cell div {
  background: #ff5a43;
}

.el-table--mini /deep/ .el-table__row:nth-child(2) td:first-child .cell div {
  background: #44d7b6;
}

.el-table--mini /deep/ .el-table__row:nth-child(3) td:first-child .cell div {
  background: #f8c51c;
}

.el-table--mini /deep/ .el-table__body-wrapper {
  padding: 10px 0;
}

.el-table::before {
  background-color: transparent;
}

.bread-tabs-head {
  color: rgba(52, 73, 94, 0.7);
  line-height: 20px;
  font-size: 14px;
}
.icon-icon-increase {
  color: #ff3b30;
}
.icon-icon-decrease {
  color: #34c759;
}
</style>