import { render, staticRenderFns } from "./groupTestPanel.vue?vue&type=template&id=6087103c&scoped=true"
import script from "./groupTestPanel.vue?vue&type=script&lang=js"
export * from "./groupTestPanel.vue?vue&type=script&lang=js"
import style0 from "./groupTestPanel.vue?vue&type=style&index=0&id=6087103c&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6087103c",
  null
  
)

export default component.exports