<template>
<!-- 小组面板 -->
  <div class="aside-panel">
    <div class="aside-head clearfix">
      <div class="aside-lt">
        <div class="bread-tabs-head">
          <div
            class="bread-tab"
            @click="getCommonGroup('recruitment')"
            :class="{ active: groupType == 'recruitment' }"
          >
            正在招募
          </div>
          <div class="bread-tab-line">/</div>
          <div
            class="bread-tab"
            @click="getCommonGroup('hot')"
            :class="{ active: groupType == 'hot' }"
          >
            热门小组
          </div>
        </div>
      </div>
      <div class="aside-rt">
        <div class="aside-more" @click="openMoreGroup">更多</div>
        <moreGroupDialog
          :visible="moreGroupVisible"
          @changeMoreVisible="(val) => (moreGroupVisible = val)"
        ></moreGroupDialog>
      </div>
    </div>
    <div class="aside-body">
      <div class="group-cells">
        <div
          v-for="(group, index) in groupList"
          class="group-cell"
          :key="index"
        >
          <div class="group-cell__hd">
            <img :src="group.avatar" alt="" style="width: 100%; height: 100%" />
          </div>
          <div class="group-cell__bd">
            <div class="group-name">
              {{ group.name }}
              <span class="group-trophy" v-if="group.ranking != -1">
                <i class="el-icon-trophy"></i>{{ group.ranking }}
              </span>
            </div>
            <div class="group-data">
              <span>{{ group.targetDay }}天{{ group.targetNum }}题</span>
              <span>成员：{{ group.userNum }}</span>
            </div>
          </div>
          <div class="group-cell__ft">
            <button type="button" class="jion-btn" @click="joinGroup(group)">
              加 入
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import moreGroupDialog from "../moreGroupDialog";
import {
  groupRecruitment,
  hotGroup,
  joinGroup,
} from "../../../service/punchClock.js";
import { mapState } from "vuex";

export default {
  name: "groupPanel",
  components: {
    moreGroupDialog,
  },
  data() {
    return {
      groupList: [],
      groupType: "recruitment",
      moreGroupVisible: false,
    };
  },
  mounted() {
    this.getCommonGroup();
  },
  computed: mapState(["userInfo"]),
  methods: {
    openMoreGroup() {
      this.moreGroupVisible = true;
    },
    getCommonGroup(type) {
      this.groupType = type ? type : "recruitment";
      if (!type || type == "recruitment") {
        groupRecruitment().then((res) => {
          if (res.success) {
            this.groupList = res.result.records;
          }
        });
      } else if (type == "hot") {
        hotGroup().then((res) => {
          if (res.success) {
            this.groupList = res.result.records;
          }
        });
      }
    },
    joinGroup(group) {
      if (!this.userInfo || !this.userInfo.id) {
        this.$router.push("/registerform");
        return;
      }
      if (!group.isOpen) {
        this.$prompt("请输入口令", "提示", {
          type: "info",
        }).then(({ value }) => {
          joinGroup(value, group.id)
            .then((res) => {
              if (res.success) {
                this.$message.success("加入小组成功！");
                this.$bus.$emit("updateGroup", "update");
              } else {
                this.$message.error(res.message);
              }
            })
            .catch((err) => {
              this.$message.error("口令错误！");
            });
        });
      } else {
        this.$confirm("确认加入此小组？", "提示", {
          type: "info",
        }).then(() => {
          joinGroup("", group.id).then((res) => {
            if (res.success) {
              this.$message.success("加入小组成功！");
              this.$bus.$emit("updateGroup", "update");
            } else {
              this.$message.error(res.message);
            }
          });
        });
      }
    },
  },
};
</script>
<style scoped>
.aside-more {
  font-weight: 500;
  color: #8894ab;
  line-height: 22px;
  font-size: 12px;
  text-decoration: underline;
  cursor: pointer;
}
.aside-lt {
  float: left;
}
.aside-rt {
  float: right;
}

.aside-panel {
  background-color: #fff;
  padding: 0 20px;
  margin-bottom: 10px;
  border-radius: 4px;
}

.aside-head {
  padding: 24px 0 6px 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.panel {
  background-color: #fff;
  margin-top: 10px;
}

.group-cell {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.group-cells {
  padding: 18px 0;
}

.group-cell__hd {
  width: 42px;
  height: 42px;
  background: #ececec;
  border-radius: 4px;
  border: 1px solid #cecece;
  margin-right: 14px;
}

.group-cell__bd {
  text-align: left;
  flex: 1;
}

.jion-btn {
  color: #fa6400;
  font-size: 12px;
  background: rgba(250, 100, 0, 0.1);
  border-radius: 2px;
  line-height: 20px;
  text-align: center;
  width: 48px;
  border-color: transparent;
  cursor: pointer;
}

.group-name {
  color: #34495e;
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 4px;
  color: #34495e;
}

.group-trophy {
  color: #b6daff;
  font-size: 12px;
  margin-left: 15px;
}

.group-data {
  line-height: 18px;
}

.group-data span {
  color: rgba(52, 73, 94, 0.7);
  font-size: 12px;
  margin-right: 10px;
}

.bread-tab {
  display: inline-block;
  padding: 0 2px;
  cursor: pointer;
}

.bread-tab.active {
  color: #fa6400;
}

.bread-tabs-head {
  color: rgba(52, 73, 94, 0.7);
  line-height: 20px;
  font-size: 14px;
}

.bread-tabs {
  float: right;
  margin-right: 40px;
}
.bread-tab-line {
  display: inline-block;
}
</style>