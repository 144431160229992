<template>
<!-- 我的小组信息面板 -->
  <div class="aside-panel myGroup">
    <div class="aside-head clearfix">
      <div class="aside-lt">我的小组</div>
    </div>
    <div class="aside-body">
      <div class="group-cells" style="text-align: center">
        <el-button
          class="create-group"
          v-if="statistics && statistics.createGroup"
          @click="createVisible = true"
          >创建小组</el-button
        >
        <createGroup
        type="create"
          :visible="createVisible"
          @changeCreateVisible="(value) => (createVisible = value)"
        ></createGroup>
        <div v-for="(group, index) in myGroup" class="group-cell" :key="index">
          <div class="group-cell__hd">
            <img :src="group.avatar" alt="" style="width: 100%; height: 100%" />
          </div>
          <div class="group-cell__bd">
            <div class="group-name">
              {{ group.name }}
              <span class="group-trophy" v-if="group.ranking != -1">
                <i class="el-icon-trophy"></i>{{ group.ranking }}
              </span>
            </div>
            <div class="group-data">
              <span>{{ group.targetDay }}天{{ group.targetNum }}题</span>
              <span>成员：{{ group.userNum }}</span>
            </div>
          </div>
          <div class="group-cell__ft">
            <button type="button" class="jion-btn" @click="goInGroup(group.id)">
              进 入
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import createGroup from "../createGroup/createGroup";
import { getUserGroup } from "../../../service/punchClock.js";
export default {
  name: "myGroupPanel",
  components: { createGroup },
  data() {
    return {
      createVisible: false,
      myGroup: [],
      statistics: {},
    };
  },
  mounted() {
    this.getGroups();
    this.$bus.$on("statistics", (e) => {
      this.statistics = e;
    });
    this.$bus.$on("updateGroup", (e) => {
      if (e == "update") {
        this.getGroups();
      }
    });
  },
  methods: {
    getGroups() {
      getUserGroup().then((res) => {
        this.myGroup = res.result;
      });
    },
    goInGroup(id) {
      this.$router.push("/myGroup?groupId=" + id);
    },
  },
};
</script>
<style scoped>
.aside-panel.myGroup {
  background-color: #fff;
  padding: 0 20px;
  margin-bottom: 10px;
  border-radius: 4px;
}

.aside-head {
  padding: 24px 0 6px 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.panel {
  background-color: #fff;
  margin-top: 10px;
}

.group-cell {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.group-cells {
  padding: 18px 0;
}

.group-cell__hd {
  width: 42px;
  height: 42px;
  background: #ececec;
  border-radius: 4px;
  border: 1px solid #cecece;
  margin-right: 14px;
}

.group-cell__bd {
  text-align: left;
  flex: 1;
}

.jion-btn {
  color: #fa6400;
  font-size: 12px;
  background: rgba(250, 100, 0, 0.1);
  border-radius: 2px;
  line-height: 20px;
  text-align: center;
  width: 48px;
  border-color: transparent;
  cursor: pointer;
}

.group-name {
  color: #34495e;
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 4px;
  color: #34495e;
}

.group-trophy {
  color: #b6daff;
  font-size: 12px;
  margin-left: 15px;
}

.group-data {
  line-height: 18px;
}

.group-data span {
  color: rgba(52, 73, 94, 0.7);
  font-size: 12px;
  margin-right: 10px;
}
.myGroup .aside-lt {
  color: #34495e;
  font-size: 14px;
  border-left: 2px solid #fa6400;
  padding-left: 8px;
}
.create-group {
  color: #fa6400;
  background: #ffffff;
  border-radius: 2px;
  border: 1px solid #fa6400;
  font-size: 14px;
  padding: 0 36px;
  line-height: 30px;
  margin: 0 auto;
  margin-bottom: 12px;
}
</style>