<template>
  <!-- 正在招募小组弹窗 -->
  <el-dialog
    title="正在招募"
    :visible.sync="dialogVisible"
    append-to-body
    width="1000px"
	 :close-on-click-modal="false"
    destroy-on-close
  >
    <el-row>
      <el-col :offset="16" :span="8">
        <el-input
          placeholder="请输入关键字"
          prefix-icon="el-icon-search"
          v-model="searchKey"
        >
          <el-button slot="append" @click="getGroups">搜索</el-button>
        </el-input>
      </el-col>
    </el-row>
    <el-row style="padding: 20px 0">
      <el-col :span="2" style="text-align: center; line-height: 400px">
        <i
          class="icon iconfont icon-prev"
          :style="{ color: currentPage != 1 ? '#fa6400' : '' }"
          @click="prevPage"
        ></i>
      </el-col>
      <el-col :span="20" style="min-height: 100px">
        <div class="group-cells">
          <div
            v-for="(group, index) in groupList"
            :class="[
              'group-cell',
              activeGroup.id && activeGroup.id == group.id ? 'active' : '',
            ]"
            :key="index"
            @click="setActiveGroup(group)"
          >
            <div class="group-cell__hd">
              <img
                :src="group.avatar"
                alt=""
                style="width: 100%; height: 100%"
              />
            </div>
            <div class="group-cell__bd">
              <div class="group-name">
                {{ group.name }}
                <span class="group-trophy" v-if="group.ranking != -1">
                  <i class="el-icon-trophy"></i>{{ group.ranking }}
                </span>
              </div>
              <div class="group-data">
                <span>{{ group.targetDay }}天{{ group.targetNum }}题</span>
                <span style="float: right">成员：{{ group.userNum }}</span>
              </div>
            </div>
          </div>
        </div>
        <div
          v-if="groupList.length == 0"
          style="text-align: center; font-size: 30px; line-height: 200px"
        >
          未搜索到结果
        </div>
      </el-col>
      <el-col :span="2" style="text-align: center; line-height: 400px"
        ><i
          class="icon iconfont icon-nex"
          :style="{
            color: currentPage != totalPage && totalPage != 0 ? '#fa6400' : '',
          }"
          @click="nextPage"
        ></i
      ></el-col>
    </el-row>
    <div slot="footer" class="dialog-foot">
      <el-button
        type="warning"
        size="small"
        class="btn warn"
        @click="joinGroup(activeGroup)"
        :disabled="!activeGroup.id"
        >加 入
      </el-button>
      <el-button
        type="info"
        size="small"
        class="btn text"
        @click="dialogVisible = false"
      >
        取 消
      </el-button>
    </div>
  </el-dialog>
</template>
<script>
import { groupRecruitment, joinGroup } from "../../service/punchClock.js";
import { mapState } from "vuex";
export default {
  name: "moreGroupDialog",
  props: {
    visible: {
      type: Boolean,
      required: true,
      default: false,
    },
  },
  data() {
    return {
      activeGroup: {},
      currentPage: 1,
      totalPage: 5,
      groupList: [],
      searchKey: "",
    };
  },
  computed: {
    dialogVisible: {
      get() {
        return this.visible;
      },
      set(val) {
        this.$emit("changeMoreVisible", val);
      },
    },
    ...mapState(["userInfo"]),
  },
  watch: {
    dialogVisible(val) {
      if (val) {
        this.activeGroup = {};
        this.getGroups();
        this.currentPage = 1;
      }
    },
  },
  methods: {
    getGroups() {
      groupRecruitment(this.currentPage, this.searchKey).then((res) => {
        if (res.success) {
          this.groupList = res.result.records;
          this.totalPage = res.result.pages;
        }
      });
    },
    setActiveGroup(item) {
      this.activeGroup = item;
    },
    joinGroup(group) {
      if (!this.userInfo || !this.userInfo.id) {
        this.$router.push("/login");
        return;
      }
      if (!group.isOpen) {
        this.$prompt("请输入口令", "提示", {
          type: "info",
        }).then(({ value }) => {
          joinGroup(value, group.id)
            .then((res) => {
              if (res.success) {
                this.$message.success("加入小组成功！");
                this.$bus.$emit("updateGroup", "update");
              } else {
                this.$message.error(res.message);
              }
            })
            .catch((err) => {
              this.$message.error("口令错误！");
            });
        });
      } else {
        this.$confirm("确认加入此小组？", "提示", {
          type: "info",
        }).then(() => {
          joinGroup("", group.id).then((res) => {
            if (res.success) {
              this.$message.success("加入小组成功！");
              this.$bus.$emit("updateGroup", "update");
            } else {
              this.$message.error(res.message);
            }
          });
        });
      }
    },
    prevPage() {
      if (this.currentPage == 1) {
        return;
      }
      this.currentPage = this.currentPage - 1;
      this.getGroups();
    },
    nextPage() {
      if (this.currentPage == this.totalPage) {
        return;
      }
      if (this.totalPage == 0) {
        return;
      }
      this.currentPage = this.currentPage + 1;
      this.getGroups();
    },
  },
};
</script>
<style scoped>
.dialog-foot {
  text-align: center;
}
.icon-prev,
.icon-nex {
  font-size: 30px;
  cursor: pointer;
}
.group-cells {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.group-cell {
  width: 45%;
  display: flex;
  padding: 10px 20px;
  border: 1px solid #e0e0e0;
  border-radius: 2px;
  height: 64px;
  margin-bottom: 12px;
}
.group-cell.active {
  border: 1px solid #fa6400;
}
.group-cell__hd {
  width: 42px;
  height: 42px;
  background: #ececec;
  border-radius: 4px;
  border: 1px solid #cecece;
  margin-right: 14px;
}

.group-cell__bd {
  text-align: left;
  flex: 1;
}

.jion-btn {
  color: #fa6400;
  font-size: 12px;
  background: rgba(250, 100, 0, 0.1);
  border-radius: 2px;
  line-height: 20px;
  text-align: center;
  width: 48px;
  border-color: transparent;
}

.group-name {
  color: #34495e;
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 4px;
  color: #34495e;
}

.group-trophy {
  color: #b6daff;
  font-size: 12px;
  margin-left: 15px;
  float: right;
}

.group-data {
  line-height: 18px;
}

.group-data span {
  color: rgba(52, 73, 94, 0.7);
  font-size: 12px;
}
</style>