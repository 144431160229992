<template>
<!-- 个人信息详细测试面板 -->
  <div class="userInfo-panel">
    <img :src="userInfo.avatar" class="user-avatar" />
    <br />
    <span class="username">{{ userInfo.username }}</span>
    <div class="userInfo-group-panel">
      <div>
        <span class="countNum">{{ statistics.groupNum }}</span>
        <br />
        加入小组
      </div>
      <div>
        <span class="countNum">{{ statistics.exerciseNum }}</span>
        <br />
        累计刷题
      </div>
      <div>
        <span class="countNum" style="font-size: 14px; line-height: 24px">{{
          statistics.ranking == -1 ? "暂无排名" : statistics.ranking
        }}</span>
        <br />
        刷题排名
      </div>
    </div>
  </div>
</template>
<script>
import { getClockStatistics } from "../../../service/punchClock.js";
import { mapState } from "vuex";
export default {
  name: "userInfoPanel",
  data() {
    return {
      statistics: {},
    };
  },
  computed: {
    ...mapState(["userInfo"]),
  },
  mounted() {
    this.getStatistics();
    this.$bus.$on("updateGroup", (e) => {
      if (e == "update") {
        this.getStatistics();
      }
    });
  },
  methods: {
    getStatistics() {
      getClockStatistics().then((res) => {
        this.statistics = res.result;
        this.$bus.$emit("statistics", res.result);
      });
    },
  },
};
</script>
<style scoped>
.userInfo-panel {
  text-align: center;
  padding-top: 35px;
  padding-bottom: 30px;
  border-radius: 4px;
}
.userInfo-panel img {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  margin-bottom: 10px;
}
.userInfo-panel .username {
  color: #34495e;
  font-size: 14px;
}
.userInfo-group-panel {
  display: flex;
  justify-content: center;
  margin-top: 16px;
  border-top: 1px solid rgba(136, 148, 171, 0.2);
  padding-top: 10px;
}
.userInfo-group-panel div {
  text-align: center;
  padding: 0 15px;
}
.userInfo-group-panel div .countNum {
  color: #fa6400;
  font-size: 16px;
}
.userInfo-panel .history {
  color: #fa6400;
  font-size: 12px;
  text-decoration: underline;
  margin-top: 24px;
  display: inline-block;
  cursor: pointer;
}
</style>